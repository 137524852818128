// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const ProtectRoute = ({ element: Element, ...rest }) => {
//     const isAuthenticated = localStorage.getItem('token');
//     console.log(isAuthenticated)
//     if (!isAuthenticated) {
//       alert("You are not a valid user !!");
//       return <Navigate to="/adminLogin" />;
//     }
  
//     return <Element {...rest} />;
// };

// export default ProtectRoute;


import React from 'react';
import { Navigate , Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const token = localStorage.getItem('token');

  return token ? <Outlet /> : <Navigate to="/adminLogin" />;
};

  

export default PrivateRoute;

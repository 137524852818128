

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainHome from './MainHome';
import FilterPage from './TestSeries/FilterPage';
import BuyNowPage from './TestSeries/BuyNowPage';
import DetailsPage from './TestSeries/DetailsPage';
import PaymentPage from './TestSeries/PaymentPage';
import AdminControls from './TestSeries/AdminControls';
import PaymentStatus from './TestSeries/PaymentStatus';
import Confirmation from './TestSeries/Confirmation';
import LoginPage from './TestSeries/LoginPage';
import ForgotPasswordPage from './TestSeries/ForgotPasswordPage';
import AdminPortal from './TestSeries/AdminPortals';
import Dashboard from './TestSeries/Dashboard';
import StudentAnswer from './TestSeries/StudentAnswer';
import AdminSend from './TestSeries/AdminSend';
import AdminGetForm from './TestSeries/AdminGetForm';
import AdminLoginPage from './TestSeries/AdminLogin';
import AdminTeam from './components/AdminTeam';
import UploadingSoon from './classes/UploadingSoon';
import PrivateRoute from './TestSeries/PrivateRoute';
import ProtectRoute from './TestSeries/ProtectRoute';
import DiscountPage from './TestSeries/DiscountPage';
import BannerAdd from './TestSeries/BannerAdd';
import Users from './TestSeries/UsersData';
import CourseContents from './TestSeries/CourseContents';
import TermsConditions from './components/Terms';
import Refund from './components/Refund';
import ContactUs from './components/ContactUs';
import './App.css';

const App = () => {

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<MainHome />} />
          <Route path="/test-series" element={<FilterPage />} />
          <Route path="/details/:title/:id" element={<DetailsPage />} />
          <Route path="/buynow/:title/:id" element={<BuyNowPage />} />
          <Route path="/payment/:id" element={<PaymentPage />} />
         
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/login" element={<LoginPage />} />
         
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        
         
         
          <Route path="/adminLogin" element={<AdminLoginPage />} />
         
          <Route path="/classes" element={<UploadingSoon />} />
          <Route path="/TermsAndCondition" element={<TermsConditions />} />
          <Route path="/RefundAndCancellation" element={<Refund />} />
          <Route path="/contact" element={<ContactUs />} />
      
          <Route element={<ProtectRoute  />}>
            <Route path="/admin" element={<AdminControls />} />
             <Route path="/adminForm" element={<AdminGetForm />} />
             <Route path="/payment-status" element={<PaymentStatus />} />
             <Route path="/adminAdd" element={<AdminPortal />} />
             <Route path="/student-answer" element={<StudentAnswer />} />
             <Route path="/teamMember" element={<AdminTeam />} />
             <Route path="/adminSend" element={<AdminSend />} />
             <Route path="/discount" element={<DiscountPage />} />
             <Route path="/banner" element={<BannerAdd/>} />
             <Route path="/users-data" element={<Users/>} />
             <Route path="/course-content" element={<CourseContents/>} />
          </Route>
          <Route element={<PrivateRoute  />}>
            <Route path="/dashboard/:courseId" element={<Dashboard />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaUserShield, FaChalkboardTeacher, FaLaptopCode, FaHandsHelping } from 'react-icons/fa';
import './WhyChooseUs.css';

function WhyChooseUs() {
  return (
    <section className="why-choose-us">
      <Container>
        <h2 className="text-center mb-4">Why Choose Us</h2>
        <p className="text-center mb-5">India's Most Affordable & Result Oriented Test Series for CA Students.</p>
        <Row>
          <Col md={3} className="d-flex">
            <Card className="feature-card equal-height">
              <Card.Body className="text-center">
                <FaUserShield size={40} className="mb-3" />
                <Card.Title>Trust</Card.Title>
                <Card.Text>
                 The CA Edge is an EdTech Brand which has always been on the path of Betterment of Students & has over delivered than what we promised. We’re strict on our Ethics & Integrity of serving the best & charging the least.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex">
            <Card className="feature-card equal-height">
              <Card.Body className="text-center">
                <FaChalkboardTeacher size={40} className="mb-3" />
                <Card.Title>Mentoring</Card.Title>
                <Card.Text>
                  We are the only Test Series wherein we organize Guidance Live Session for students which is a much needed facility for Hand Holding Support to each & Every Student.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex">
            <Card className="feature-card equal-height">
              <Card.Body className="text-center">
                <FaLaptopCode size={40} className="mb-3" />
                <Card.Title>Technology</Card.Title>
                <Card.Text>
                  We have the Best user experience Portal for a smoother test series experience along with we provide Quick Whatsapp support too.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex">
            <Card className="feature-card equal-height">
              <Card.Body className="text-center">
                <FaHandsHelping size={40} className="mb-3" />
                <Card.Title>CSR</Card.Title>
                <Card.Text>
                  We provide Concessions & Scholarships to Students belonging from Low Income Families or Students with Single Parent under our CSR Initiative.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhyChooseUs;

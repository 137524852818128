import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import image1 from "../images/swati.jpg";
import image2 from "../images/anshul.jpg";
import image3 from "../images/kamal.jpg";
import image4 from "../images/jatin.jpeg";
import image from "../images/back.jpg";
import './Testimonials.css';

function Testimonials() {
  return (
   <div style={{backgroundImage:`url(${image})`}}>
     <Container className="mt-5 py-4">
      <h2 className="testimo">What Our Students Say</h2>
      <Carousel>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={8} className="testimonial">
              <img src={image1} alt="Student A" className="testimonial-avatar" />
              <p>"Online test series by The Ca Edge is absolutely amazing and very helpful.Highly recommended.Thanks to Avnish sir for the great move 👍🏻"</p>
              <h5>Swati - CA Final</h5>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={8} className="testimonial">
              <img src={image2} alt="Student B" className="testimonial-avatar" />
              <p>"I am Anshul sahni from ghaziabad, Maina apki DT nd IDT ki test series li thi... or maina aj dt ka 2nd test appear kiya.. Now I am so confident, your test paper is too good and very logical, test are moderate level.Thanks alot sir❤❤"</p>
              <h5>Anshul sahni</h5>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={8} className="testimonial">
              <img src={image3} alt="Student C" className="testimonial-avatar" />
              <p>"I have been enrolled in a test series @caedge.com.It was phenomenal.It covers relevant and conceptual questions. It provides sufficient and appropriate value for money."</p>
              <h5>Kamal Bansal - CA Inter</h5>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={8} className="testimonial">
              <img src={image4} alt="Student C" className="testimonial-avatar" />
              <p>"I recently enrolled in the caedge test series for my CA exam preparation, and I must say it has been a transformative experience."</p>
              <h5>Jatin - CA Inter</h5>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </Container>
   </div>
  );
}

export default Testimonials;

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FiAlertCircle } from 'react-icons/fi'; // Import an icon from react-icons library
import Header from '../components/Header';

const UploadingSoon = () => {
  return (
    <>
    <div>
      <Header/>
      <div className="d-flex justify-content-center align-items-center ">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Card className="shadow border-0 rounded-lg p-4">
              <Card.Body>
                <h1 className="text-center mb-4 text-primary">Stay tuned for exciting content updates - Coming Soon!</h1>
                <p className="lead text-center mb-4">
                  We are currently working on adding more content to enhance your experience. Stay tuned!
                </p>
                <div className="text-center text-danger mb-4">
                  <FiAlertCircle size={30} />
                  <span className="ms-2">This page is under construction</span>
                </div>
                <p>
                  While we prepare new content, feel free to explore other sections of our platform or check back later for updates.
                </p>
                <p>
                  Our team is dedicated to providing you with valuable resources to help you make the most of your experience. Thank you for your patience and understanding.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
    </>
  );
};

export default UploadingSoon;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CourseContents = () => {
  const [contents, setContents] = useState([]);
  const [newContent, setNewContent] = useState({ courseId: '', title: '', content: '', file: null });

  useEffect(() => {
    const getContents = async () => {
      try {
        const response = await axios.get('https://thecaedge.com/api/course-contents');
        setContents(response.data);
      } catch (error) {
        console.error('Error fetching contents:', error);
      }
    };
    getContents();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://thecaedge.com/api/course-content/${id}`);
      setContents(contents.filter((content) => content.id !== id));
    } catch (error) {
      console.error('Error deleting content:', error);
    }
  };

  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append('courseId', newContent.courseId);
    formData.append('title', newContent.title);
    formData.append('content', newContent.content);
    if (newContent.file) {
      formData.append('file', newContent.file);
    }

    try {
      await axios.put(`https://thecaedge.com/api/course-content/${id}`, formData);
      setContents(contents.map((content) => (content.id === id ? { ...content, ...newContent } : content)));
    } catch (error) {
      console.error('Error updating content:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      setNewContent({ ...newContent, [name]: files[0] });
    } else {
      setNewContent({ ...newContent, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('courseId', newContent.courseId);
    formData.append('title', newContent.title);
    formData.append('content', newContent.content);
    if (newContent.file) {
      formData.append('file', newContent.file);
    }

    try {
      await axios.post('https://thecaedge.com/api/course-content', formData);
      // Fetch the updated list of contents
      const response = await axios.get('https://thecaedge.com/api/course-contents');
      setContents(response.data);
    } catch (error) {
      console.error('Error adding content:', error);
    }
  };

  return (
    <div>
    <div className="text-center my-4">
    <Link to={'/admin'} className="btn btn-outline-primary mr-2">HomePage</Link>
      
    </div>
    <div style={styles.container}>
      <h1 style={styles.heading}>Course Contents</h1>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>ID</th>
            <th style={styles.th}>Course ID</th>
            <th style={styles.th}>Title</th>
            <th style={styles.th}>Content</th>
            <th style={styles.th}>File</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contents.map((content) => (
            <tr key={content.id} style={styles.tr}>
              <td style={styles.td}>{content.id}</td>
              <td style={styles.td}>{content.courseId}</td>
              <td style={styles.td}>{content.title}</td>
              <td style={styles.td}>{content.content}</td>
              <td style={styles.td}>
                {content.file_path ? <a href={content.file_path} target="_blank" rel="noopener noreferrer">View File</a> : 'No File'}
              </td>
              <td style={styles.td}>
                <button style={styles.button} onClick={() => handleDelete(content.id)}>Delete</button>
                <button style={styles.button} onClick={() => handleUpdate(content.id)}>Update</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 style={styles.heading}>Add/Update Course Content</h2>
      <form style={styles.form} onSubmit={handleSubmit}>
        <input
          type="text"
          name="courseId"
          placeholder="Course ID"
          value={newContent.courseId}
          onChange={handleChange}
          style={styles.input}
        />
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={newContent.title}
          onChange={handleChange}
          style={styles.input}
        />
        <textarea
          name="content"
          placeholder="Content"
          value={newContent.content}
          onChange={handleChange}
          style={styles.textarea}
        ></textarea>
        <input
          type="file"
          name="file"
          onChange={handleChange}
          style={styles.input}
        />
        <button type="submit" style={styles.button}>Add/Update Content</button>
      </form>
    </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  th: {
    backgroundColor: '#f4f4f4',
    color: '#333',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  tr: {
    ':nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
  },
  td: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#0056b3',
    },
    margin: '0 5px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
  },
  textarea: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    minHeight: '100px',
  },
};

export default CourseContents;

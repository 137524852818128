import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import PaymentForm from './PaymentForm'; // Assuming the PaymentForm component is in a separate file

const PaymentPage = () => {
  const { id } = useParams();
  const [item, setItem] = useState([]);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`https://thecaedge.com/api/items/${id}`);
        setItem(response.data); // Ensure this matches your backend response format
      } catch (error) {
        console.error('Error fetching item:', error);
      }
    };

    fetchItem();
  }, [id]);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="shadow">
            <CardBody>
              <h2 className="text-center mb-4">Payment Details</h2>
              {item.length > 0 && (
                <div className="mb-4">
                  <h3>{item[0].title}</h3>
                  <p>Price: &#8377; 
               {item[0].discount > 0 ? (
                   <>
                    <span style={{ textDecoration: 'line-through' }}>{item[0].amount}</span>
                     <div style={{color:'red'}}> Discount Price:&#8377;{ (item[0].amount - (item[0].amount * (item[0].discount / 100))).toFixed(2)}</div>
                   </>
                 ) : item[0].amount}</p>
                </div>
              )}
              <PaymentForm item={item} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPage;

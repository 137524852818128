import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import axios from 'axios';
import { load } from '@cashfreepayments/cashfree-js';

const PaymentForm = ({ item }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [cashfree, setCashfree] = useState(null);

  useEffect(() => {
    const initializeSDK = async () => {
      const cfInstance = await load({ mode: 'production' });
      setCashfree(cfInstance);
    };
    initializeSDK();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentSubmission = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const orderId = `order_${new Date().getTime()}`;

    try {
     const response = await axios.post('https://thecaedge.com/payment/generate-token', {
  orderId,
  orderAmount: item.length > 0 ? (item[0].discount > 0
    ? (item[0].amount - (item[0].amount * (item[0].discount / 100))).toFixed(2)
    : item[0].amount) : 0, // Handle the case when item length is 0
  customerEmail: formData.email,
  customerPhone: formData.phone,
  CourseId: item.length > 0 ? item[0].id : null // Adjust CourseId assignment based on item length
});


      const { payment_session_id } = response.data;

      const checkoutOptions = {
        paymentSessionId: payment_session_id,
        redirectTarget: '_self',
      };

      cashfree.checkout(checkoutOptions);

      // Poll for payment status after a delay
      setTimeout(() => checkPaymentStatus(orderId), 5000);
    } catch (error) {
      console.error('There was an error processing the payment!', error);
      alert(`Payment failed: ${error.response?.data?.message || error.message}`);
      setIsProcessing(false);
    }
  };

  const checkPaymentStatus = async (orderId) => {
    try {
      const statusResponse = await axios.get(`https://thecaedge.com/payment/status/${orderId}`);
      const paymentStatus = statusResponse.data.status;

      if (paymentStatus === 'SUCCESS') {
        await axios.post('https://thecaedge.com/payment/user-info', {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          courseId: item[0].id
        });
        alert('Payment successful!');
        setIsProcessing(false);
      } else if (paymentStatus === 'FAILED') {
        alert('Payment failed.');
        setIsProcessing(false);
      } else {
        // If payment is not completed, check again after delay
        setTimeout(() => checkPaymentStatus(orderId), 5000); // Check every 5 seconds
      }
    } catch (error) {
      console.error('Error fetching payment status:', error);
      setTimeout(() => checkPaymentStatus(orderId), 5000); // Retry after delay in case of error
    }
  };

  return (
    <Form onSubmit={handlePaymentSubmission}>
      <FormGroup>
        <Label for="name">Name</Label>
        <Input 
          type="text" 
          id="name" 
          name="name" 
          value={formData.name} 
          onChange={handleInputChange} 
          required 
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email} 
          onChange={handleInputChange} 
          required 
        />
      </FormGroup>
      <FormGroup>
        <Label for="phone">Phone</Label>
        <Input 
          type="text" 
          id="phone" 
          name="phone" 
          value={formData.phone} 
          onChange={handleInputChange} 
          required 
        />
      </FormGroup>
      <Button type="submit" color="primary" disabled={isProcessing}>
        {isProcessing ? 'Processing...' : 'Submit Payment'}
      </Button>
    </Form>
  );
};

export default PaymentForm;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import axios from 'axios';
import { statesAndCities } from './stateAndCities';
import Header from '../components/Header';

const BuyNowPage = () => {
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    registration: '',
    otp: '',
    email: '',
    password: '',
    confirmPassword: '',
    address: '',
    state: '',
    city: '',
    id
  });
  const [cities, setCities] = useState([]);
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`https://thecaedge.com/api/items/${id}`);
        setItem(response.data[0]); // Update state with the first item from the array
      } catch (error) {
        console.error('Error fetching item:', error);
      }
    };

    fetchItem();
  }, [id]);

  useEffect(() => {
    if (formData.state) {
      setCities(statesAndCities[formData.state] || []);
      setFormData({ ...formData, city: '' });
    } else {
      setCities([]);
    }
  }, [formData.state]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOtpGeneration = async () => {
    try {
      const response = await axios.post('https://thecaedge.com/verify/generate-otp', { email: formData.email });
      if (response.status === 200) {
        setOtpGenerated(true);
        alert('OTP generated and sent to your Email Id');
      }
    } catch (error) {
      console.error("There was an error generating the OTP!", error);
    }
  };

  const handleOtpVerification = async () => {
    try {
      const response = await axios.post('https://thecaedge.com/verify/verify-otp', { email: formData.email, otp: formData.otp });
      
      if (response.status === 200) {
        setOtpVerified(true);
        alert('OTP verified successfully');
      }
    } catch (error) {
      if (error.response) {
        // If the error response is available, check the message
        const { message } = error.response.data;
        alert(message);
      } else {
        console.error("There was an error verifying the OTP!", error);
        alert('There was an error verifying the OTP!');
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!otpVerified) {
      alert("Please verify the OTP first");
      return;
    }

    try {
      const response = await axios.post('https://thecaedge.com/verify/submit-details', formData);
      if (response.status === 200) {
        alert('Form submitted successfully');
        navigate(`/payment/${id}`, { state: formData });
      }
    } catch (error) {
      if (error.response) {
        // If the error response is available, check the message
        const { message } = error.response.data;
        alert(message);
      }
      console.error("There was an error submitting the form!", error);
      if (error.response && error.response.status === 409) {
        if (window.confirm("User with this email already exists. Do you want to replace the existing user data?")) {
          try {
            const deleteResponse = await axios.post('https://thecaedge.com/verify/delete-user', { email: formData.email });
            if (deleteResponse.status === 200) {
              alert('Existing user data deleted. Please resubmit the form.');
            }
          } catch (deleteError) {
            console.error("There was an error deleting the user!", deleteError);
          }
        }
      } else {
        alert('Error submitting form');
      }
    }
  };

  return (
    <>
      <Header />
      <Container className="mt-5" style={{ marginBottom:"5%"}}>
        <Row>
          <Col md={6}>
            {item && (
              <Card className="shadow mb-4">
                <CardBody>
                  <h2 className="text-center mb-4">{item.title}</h2>
                  <div className="text-center">
                    <img src={item.image} alt="Product" className="img-fluid rounded mb-3" style={{ maxWidth: '100%', height: '50vh' , width: "50vb" }} />
                  </div>
                  <ul className="list-unstyled">
                    <li><strong>Title:</strong> {item.title}</li>
                    <li><strong>Amount: &#8377;
               
               {item.discount > 0 ? (
                   <>
                    <span style={{ textDecoration: 'line-through' }}>{item.amount}</span>
                     <div style={{color:'red'}}> Discounted Price:&#8377;{ (item.amount - (item.amount * (item.discount / 100))).toFixed(2)}</div>
                   </>
                 ) : item.amount}
               </strong></li>
                    <li><strong>Description:</strong> {item.description}</li>
                  </ul>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col md={6}>
            <Card className="shadow">
              <CardBody>
                <h2 className="text-center mb-4">Buy Now</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        className="form-control mb-3"
                        placeholder="First Name *"
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                        className="form-control mb-3"
                        placeholder="Last Name *"
                      />
                    </Col>
                  </Row>
                  <input
                    type="number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Mobile Number *"
                  />
                  <input
                    type="text"
                    name="registration"
                    value={formData.registration}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="ICAI Registration Number *"
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Email Address *"
                  />
                  <Button type="button" onClick={handleOtpGeneration} disabled={otpGenerated} className="btn btn-primary mb-3">Generate OTP</Button>
                  <input
                    type="number"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="OTP *"
                  />
                  <Button type="button" onClick={handleOtpVerification} disabled={otpVerified} className="btn btn-primary mb-3">Verify OTP</Button>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Create Password *"
                  />
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Confirm Your Password *"
                  />
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Address *"
                  />
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                  >
                    <option value="">Select State</option>
                    {Object.keys(statesAndCities).map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                  <select
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                  >
                    <option value="">Select State First</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>{city}</option>
                    ))}
                  </select>
                  <Button type="submit" className="btn btn-primary" disabled={!otpVerified}>Proceed to Payment</Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BuyNowPage;

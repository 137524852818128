import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Input } from 'reactstrap';
import axios from 'axios';
import Header from '../components/Header';
import image from "../images/banner.jpg";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    emailOrPhone: '',
    password: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://thecaedge.com/api/login', formData);
      if (response.status === 200) {
        alert('Login successful');
        const { courseId, user } = response.data;
        localStorage.setItem('authToken', "1234567890");
        console.log('User in LoginPage:', user); // Log user object to verify
        navigate(`/dashboard/${courseId}`, { state: { user } });
      }
    } catch (error) {
      console.error("There was an error logging in!", error);
      if (error.response) {
        if (error.response.status === 404) {
          alert('Email not found , Please register.');
        } else if (error.response.status === 402) {
          alert('Payment not completed. Please complete the payment to access your account.');
        } else {
          alert('Login failed');
        }
      } else {
        alert('Login failed');
      }
    }
  };

  return (
    <>
      <Header />
      <div style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        position: 'relative',
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.6)',
          zIndex: 1
        }}></div>
        <Container style={{ zIndex: 2, marginTop: '-5%' }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <Card className="shadow-lg border-0 rounded" style={{
                background: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(10px)',
                animation: 'fadeIn 1s ease-in-out',
                borderRadius: '20px',
              }}>
                <CardBody>
                  <h2 className="text-center mb-4" style={{
                    fontWeight: 'bold',
                    color: '#333',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    animation: 'slideIn 1s ease-in-out'
                  }}>Login</h2>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="emailOrPhone"
                        value={formData.emailOrPhone}
                        onChange={handleChange}
                        required
                        placeholder="Email or Phone *"
                        style={{
                          borderRadius: '20px',
                          padding: '10px 15px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          animation: 'fadeIn 1.5s ease-in-out'
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        placeholder="Password *"
                        style={{
                          borderRadius: '20px',
                          padding: '10px 15px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          animation: 'fadeIn 1.5s ease-in-out'
                        }}
                      />
                    </FormGroup>
                    <Button type="submit" color="primary" className="w-100 mb-3" style={{
                      borderRadius: '20px',
                      padding: '10px 0',
                      background: 'linear-gradient(45deg, #6b5b95, #b8a9c9)',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      animation: 'fadeIn 2s ease-in-out'
                    }}>Login</Button>
                  </Form>
                  <div className="text-center">
                  <Button color="link" onClick={() => navigate(`/test-series`)} style={{
                      color: '#6b5b95',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                      animation: 'fadeIn 2.5s ease-in-out'
                    }}>Sign Up</Button>
                    <Button color="link" onClick={() => navigate(`/forgot-password`)} style={{
                      color: '#6b5b95',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                      animation: 'fadeIn 2.5s ease-in-out'
                    }}>Forgot Password?</Button>
                 
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <style>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        @keyframes slideIn {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        @media (max-width: 968px) {
          .card {
            width: 90%;
            margin: auto;
             margin-top:-25%;
          }
        }
        @media (max-width: 768px) {
          .card {
            width: 90%;
            margin: auto;
             margin-top:-25%;
          }
        }
      
        @media (max-width: 576px) {
          .card {
            width: 100%;
            margin: auto;
            margin-top:-25%;
          }
        }
      `}</style>
    </>
  );
};

export default LoginPage;

import React from 'react';
import './MoreFeatures.css';

const MoreFeatures = () => {
  return (
    <div className="features-wrapper">
      <h1>Our Features</h1>
      <h2>India's Most Affordable & Result Oriented Test Series for CA Students</h2>
      <div className="features-list">
        <div className="feature-card">
          <div className="feature-icon">🎓</div>
          <h3>Experienced Faculties</h3>
          <p>We have built a team of Qualified CAs & also some Teachers who have been teaching CA Students for years. Our Test Papers are prepared & also checked by these experienced faculties.</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">📚</div>
          <h3>100% Coverage of Syllabus</h3>
          <p>Our Test Papers cover each corner of the ICAI Module & include questions based on PYQs, RTPs, MTPs & much more for the most exhaustive coverage & best possible preparations of our students.</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">📺</div>
          <h3>Live Session</h3>
          <p>We conduct live sessions wherein we guide our students with study techniques, revision strategies, exam strategies & collectively solve students' difficulties to be a helping hand for them throughout their journey.</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">📝</div>
          <h3>ICAI Level Tests</h3>
          <p>Our test papers are prepared by ex-examiners & experienced faculties, keeping in mind the complexities of ICAI exams, leading to efficient preparation for students who solve all the tests with utmost discipline.</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">💸</div>
          <h3>Affordability</h3>
          <p>The CA Edge always aims at providing maximum support to all students to make their journey smoother & memorable. Hence, we provide exam preparatory services at the lowest costs in India to make it affordable for everyone.</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">🗓️</div>
          <h3>Scheduled Tests</h3>
          <p>Discipline & consistency is the key to success. Hence, we also provide students with a proper timetable (datesheet) which they can follow to write our test series. However, following the schedule is not at all mandatory.</p>
        </div>
      </div>
    </div>
  );
}

export default MoreFeatures;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';

const ContactUs = () => {
  return (
    <>
      <Header />
      <Container className="mt-5">
        <h1 className="text-center mb-4">Contact Us</h1>

        <Row className="mb-4">
          <Col>
            <h3>Merchant Legal Entity Name:</h3>
            <p>THE CA EDGE</p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3>Registered Address:</h3>
            <p>Kh. No. 368 Basant Vihar Guldhar II<br />
              Sector 23 Sanjay Nagar,<br />
              Ghaziabad, Uttar Pradesh,<br />
              PIN: 201002</p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3>Operational Address:</h3>
            <p>Kh. No. 368 Basant Vihar Guldhar II<br />
              Sector 23 Sanjay Nagar,<br />
              Ghaziabad, Uttar Pradesh,<br />
              PIN: 201002</p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3>Telephone No:</h3>
            <p>9311159799</p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3>E-Mail ID:</h3>
            <p>thecaedge@gmail.com</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUs;

import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from '../images/file.png';
import { Link } from "react-router-dom";

const Header = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const navData = [
    { name: "Home", path: "/" },
    { name: "Test Series", path: "/test-series" },
    { name: "Classes", path: "/classes" },
    { name: "Login", path: "/login" },
  ];

  const styles = {
    navbar: {
      minHeight: "80px",
      maxHeight: screenWidth > 1000 ? "80px" : "40px",
      borderRadius: "10px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      backgroundColor: expanded ? "white" : "black", // Adjusted background color based on expanded state
      marginBottom: '10px',
    },
    brandImage: {
      maxWidth: "20vh",
      maxHeight: screenWidth > 1000 ? "15vh" : "12vh",
      marginTop: screenWidth > 1000 ? "-2vh" : "-6vh",
    },
    toggleButton: {
      marginTop: screenWidth > 1000 ? "" : "-3vh",
      paddingBottom: screenWidth > 1000 ? "" : "0",
      backgroundColor: "black",
   
    },
    navLink: {
      transition: "color 0.3s ease",
      color: expanded ? "black" : "white",
      padding: "0.5rem 1rem",
    },
    navLinkHover: {
      color: "#3498db",
    },
    forMargin:{
      marginBottom: screenWidth > 1000 ? "5%" : (screenWidth <= 1000 && screenWidth > 750) ? "10%" : "20%"

    },
  };

  return (
    <div style={styles.forMargin}>
      <Navbar
      expand="lg"
      bg="dark"
      variant="dark"
      className="p-3 fixed-top"
      style={styles.navbar}
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            src={logo}
            alt="Logo"
            style={styles.brandImage}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={styles.toggleButton}
          onClick={handleToggle}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto fs-5">
            {navData.map((data, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={data.path}
                style={styles.navLink}
                onMouseEnter={(e) => {
                  e.target.style.color = styles.navLinkHover.color;
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = expanded ? "black" : "white";
                }}
              >
                {data.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
};

export default Header;

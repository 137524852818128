import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import './Hero.css'; // Assuming this file contains additional styles for the hero section
import { Link } from 'react-router-dom';

function Hero() {
  const [hovered, setHovered] = useState(false);

  return (
    <div className="hero-section">
      <Container className="text-center">
        <h1 className="hero-title">Welcome to THE CA EDGE</h1>
        <p className="hero-subtitle">Your Path to Success in Chartered Accountancy</p>
        <Link to="/test-series">
          <Button
            className={`btn-lg fw-bold ${hovered ? 'text-white' : 'text-dark'}`}
            variant={hovered ? "dark" : "light"}
            style={{ transition: 'background-color 0.3s, color 0.3s', marginTop: "20px" }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            Get Started
          </Button>
        </Link>
        <div className="scroll-indicator">↓</div>
      </Container>
    </div>
  );
}

export default Hero;

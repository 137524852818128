import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Hero from './components/Hero';
import Testimonials from './components/Testimonials';
import Features from './components/Features';
import WhyChooseUs from './components/WhyChooseUs';
import MeetOurTeam from './components/MeetOurTeam';
import MoreFeatures from './components/MoreFeatures';
import BannerComponent from './components/BannerComponent';
import BannerStatic from './components/BannerStatic';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function MainHome() {
  return (
    <div>
      <Header />
      <Hero />
      <BannerStatic/>
      <BannerComponent/>
      <Home />
      <Features />
      <MeetOurTeam/>
      <WhyChooseUs />
     <MoreFeatures/>
      <Testimonials />
      <Footer />
    </div>
  );
}

export default MainHome;

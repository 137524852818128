import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PaymentStatus from './PaymentStatus';
const Confirmation = () => {
  const [message, setMessage] = useState('Processing Payment...');

  useEffect(() => {
    // Simulate a payment process with a 3-second delay
    setTimeout(() => {
      setMessage('Thank you for your purchase.');
    }, 3000);
  }, []);

  const styles = {
    app: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f4f4f4',
      color: '#333',
      textAlign: 'center',
      margin: 0,
      padding: 0,
    },
    header: {
      backgroundColor: '#4CAF50',
      padding: '20px',
      color: 'white',
    },
    main: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    messageContainer: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      marginBottom: '20px',
    },
    h2: {
      fontSize: '24px',
      color: '#333',
    },
    linkContainer: {
      marginTop: '20px',
    },
    link: {
      color: '#4CAF50',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    footer: {
      backgroundColor: '#333',
      color: 'white',
      textAlign: 'center',
      padding: '10px 0',
    },
  };

  return (
    <div style={styles.app}>
      <header style={styles.header}>
        <h1>Payment Portal</h1>
      </header>
      <main style={styles.main}>
        <div style={styles.messageContainer}>
          <h2 style={styles.h2}>{message}</h2>
        </div>
        <div style={styles.linkContainer}>
          <Link to={'/'} style={styles.link}>Login For Dashboard</Link>
        </div>
      </main>
      <footer style={styles.footer}>
        <p>&copy; 2024 Payment Portal. All rights reserved.</p>
      </footer>
      <div style={{display:"none"}}>
      <PaymentStatus/>
      </div>
    </div>
  );
};

export default Confirmation;

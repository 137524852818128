import React from 'react';
import image1 from "../images/image1.jpg"
import image2 from "../images/image2.jpg"
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Home.css';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <Container className="mt-5 home-container">
      <Row>
        <Col md={6}>
          <Card className="custom-card">
            <Card.Img variant="top" src= {image1} className="card-image" />
            <Card.Body>
              <Card.Title>CA/CS/CMA Test Series</Card.Title>
              <Card.Text>
                Prepare with our comprehensive test series designed by experts to help you excel in your CA exams.
              </Card.Text>
              <Link to={"/test-series"}><Button   className='bg-dark'>Buy Test Series</Button></Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="custom-card">
            <Card.Img variant="top" src={image2} className="card-image" />
            <Card.Body>
              <Card.Title>Recorded Classes</Card.Title>
              <Card.Text>
                Access our library of recorded classes anytime, anywhere to reinforce your learning and preparation.
              </Card.Text>
              <Link to={"/classes"}><Button   className='bg-dark'>Buy Classes</Button></Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;


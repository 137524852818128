import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Button } from 'reactstrap';
import { FiUploadCloud } from 'react-icons/fi'; // Importing cloud upload icon
import axios from 'axios';

const DashboardContent = ({ user, courseId }) => {
  const [formData, setFormData] = useState({
    file: ''
  });
  const [contentSent, setContentSent] = useState(false);
  const [fileName, setFileName] = useState('No file chosen');

  useEffect(() => {
    // Check if content has been sent previously
    const contentSentStatus = localStorage.getItem('contentSent');
    const contentSentTime = localStorage.getItem('contentSentTime');
    if (contentSentStatus === 'true') {
      const currentTime = new Date().getTime();
      const twentyFourHours = 24*60*60*1000;
      if (currentTime - parseInt(contentSentTime) < twentyFourHours) {
        setContentSent(true);
      } else {
        // If more than 24 hours have passed, clear the localStorage items
        localStorage.removeItem('contentSent');
        localStorage.removeItem('contentSentTime');
      }
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      file
    });
    setFileName(file.name); // Set the name of the chosen file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if content already sent
    const contentSent = localStorage.getItem('contentSent');
    if (contentSent) {
      alert('Content already sent!');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('file', formData.file);
    formDataToSend.append('userEmail', user.email);
    formDataToSend.append('userName', `${user.firstName} ${user.lastName}`); // Combine first and last name
    formDataToSend.append('courseId', courseId);

    try {
      const response = await axios.post('https://thecaedge.com/api/forTest', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 201) {
        alert('Answersheet sent successfully');
        // Mark content as sent and set expiration time to 24 hours
        localStorage.setItem('contentSent', 'true');
        localStorage.setItem('contentSentTime', new Date().getTime().toString());
        setContentSent(true);
        setFormData({ file: '' });
        setFileName('No file chosen'); // Reset the file name display
      }
    } catch (error) {
      console.error('There was an error adding the content!', error);
      alert('Failed to add content');
    }
  };

  return (
    <Container className="mt-5">
      <Card className="shadow border-0">
        <CardBody className="p-5 bg-light rounded">
          <div className="text-center mb-4">
            <h3 className="mb-3">Upload Your Answersheet</h3>
            <p className="text-muted">Upload your answersheet only once for particular test. Additional attempts will not be considered.</p>
            <p className="text-muted">Once uploaded, the upload button will be visible again after 24 hours.</p>
          </div>
          {contentSent ? (
            <div className="text-center">
              <p className="mb-0">Answersheet has been sent.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-3 text-center">
                <input
                  type="file"
                  className="d-none"
                  id="fileInput"
                  accept=".pdf"
                  onChange={handleFileChange}
                  required
                />
                <label htmlFor="fileInput" className="btn btn-primary btn-lg">
                  <FiUploadCloud className="me-2" /> Choose File
                </label>
                <p className="text-muted mb-0">{fileName}</p> {/* Display chosen file name */}
              </div>
              <div className="text-center">
                <Button type="submit" color="success" className="btn-lg">
                  Upload Content
                </Button>
              </div>
            </form>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default DashboardContent;

import React from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardTitle } from 'reactstrap';

const TestPage = ({ filters, onFilterChange, onSubmit, onReset }) => {
  return (
    <Card className="shadow-sm">
      <CardBody>
        <CardTitle tag="h5" className="text-center mb-4">Filter Options</CardTitle>
        <Form>
          {filters.map((filter, index) => (
            <FormGroup key={index}>
              <Label for={`filter-${index}`}>{filter.label}</Label>
              <Input
                type="select"
                id={`filter-${index}`}
                value={filter.selectedOption}
                onChange={(e) => onFilterChange(index, e.target.value)}
                disabled={filter.options.length === 0}
              >
                <option value="">Select</option>
                {filter.options.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ))}
          <div className="d-flex justify-content-between mt-4">
            <Button color="primary" onClick={(e) => { e.preventDefault(); onSubmit(); }} className="mr-2">
              Submit
            </Button>
            <Button color="secondary" onClick={(e) => { e.preventDefault(); onReset(); }}>
              Reset
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default TestPage;


import React, { useState, useEffect } from 'react';
import { useParams , Link} from 'react-router-dom';
import { Container, Row, Col, Card, CardImg, CardBody, Button, ListGroup, ListGroupItem } from 'reactstrap';
import axios from 'axios';
import Header from '../components/Header';

const BuyNowPage = () => {
  const { id , title } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`https://thecaedge.com/api/items/${id}`);
        setItem(response.data[0]);
      } catch (error) {
        console.error('Error fetching item:', error);
      }
    };

    fetchItem();
  }, [id]);

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div style={{marginTop:'6%'}}>
      <Header />
      <Container fluid className="mt-5 mb-5">
        <Row className="justify-content-center">
          <Col xs={12} lg={6}>
            <Card className="shadow-lg border-0 rounded-0">
            <CardBody className="text-center">
                <h2 className="mb-3">Price: &#8377; {item.discount > 0 ? (
                   <>
                    <span style={{ textDecoration: 'line-through' }}>{item.amount}</span>
                     <div style={{color:'red'}}> Discounted Price:&#8377;{ (item.amount - (item.amount * (item.discount / 100))).toFixed(2)}</div>
                   </>
                 ) : item.amount}</h2>
                <p className="mb-4 product-description">{item.description}</p>
                <Link to={`/buynow/${title}/${id}`}>  <Button color="primary" size="lg">Buy Now</Button></Link>
              </CardBody>
              <div className="text-center">
                    <img src={item.image} alt="Product" className="img-fluid rounded mb-3" style={{ maxWidth: '100%', height: '50vh' , width: "60vb" }} />
                  </div>
              
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="shadow-lg rounded-0">
              <CardBody className="p-4">
                <h2 className="text-center mb-4 text-primary">Product Details</h2>
                <ListGroup flush>
                  <ListGroupItem className="border-0 py-2"><strong>Title:</strong> {item.title}</ListGroupItem>
                  <ListGroupItem className="border-0 py-2"><strong>Price:</strong> &#8377; {item.discount > 0 ? (
                   <>
                    <span style={{ textDecoration: 'line-through' }}>{item.amount}</span>
                     <div style={{color:'red'}}> Discounted Price:&#8377;{ (item.amount - (item.amount * (item.discount / 100))).toFixed(2)}</div>
                   </>
                 ) : item.amount}</ListGroupItem>
                  <ListGroupItem className="border-0 py-2"><strong>Description:</strong> {item.description}</ListGroupItem>
                  <ListGroupItem className="border-0 py-2"><strong>Course:</strong> {item.category1}</ListGroupItem>
                  <ListGroupItem className="border-0 py-2"><strong>Level:</strong> {item.category2}</ListGroupItem>
                  <ListGroupItem className="border-0 py-2"><strong>Attempt:</strong> {item.category3}</ListGroupItem>
                  <ListGroupItem className="border-0 py-2"><strong>Type:</strong> {item.category4}</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
};

export default BuyNowPage;

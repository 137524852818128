import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-column">
            <h2>About Us</h2>
            <p>We are a team of professionals dedicated to providing the best services to our clients.</p>
            <div className="contact">
              <span><i className="fas fa-phone"></i> +91-9717190095 , +91-9311159799</span>
              <span><i className="fas fa-envelope"></i> thecaedge@gmail.com</span>
            </div>
          </div>
          <div className="footer-column">
            <h2>Quick Links</h2>
            <ul>
              <li><a href="/">Home</a></li>
              <li><Link to={'/test-series'}>Services</Link></li>
              <li><Link to={'/TermsAndCondition'}>Terms and Conditions</Link></li>
              <li><Link to={'RefundAndCancellation'}>Refund and Cancellation</Link></li>
              <li><Link to={'contact'}>Contact Us</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h2>Follow Us</h2>
            <div className="social-icons">
              <a href="https://x.com/the_caedge" target='_blank'><i className="fab fa-twitter"></i></a>
              <a href="https://www.youtube.com/@THECAEDGE" target='_blank'><i className="fab fa-youtube"></i></a>
              <a href="https://www.linkedin.com/in/thecaedge/" target='_blank'><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        2024 The CA Edge. All rights reserved.
      </div>
      <div style={{textAlign:"center"}}>
        <Link to={"/adminLogin"} target='_blank'>Admin</Link>
      </div>
      <div style={{textAlign: "center", fontSize: "small"}}>
        <a href="https://www.linkedin.com/in/shubham-singh-2a2ba6235/" target="_blank" rel="noopener noreferrer">Website Created By Shubham</a>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import axios from 'axios';
import DashboardContent from './DasboardContent';

const Dashboard = () => {
  const { courseId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [contents, setContents] = useState([]);
  const [answerSheets, setAnswerSheets] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`https://thecaedge.com/api/content/${courseId}`);
        setContents(response.data);
      } catch (error) {
        console.error('Error fetching course content:', error);
      }
    };

    fetchContent();
  }, [courseId]);

  useEffect(() => {
    const fetchAnswerSheets = async () => {
      try {
        const response = await axios.get(`https://thecaedge.com/api/forAnswer/${courseId}/${user.email}`);
        setAnswerSheets(response.data);
      } catch (error) {
        console.error('Error fetching answer sheets:', error);
      }
    };

    fetchAnswerSheets();
  }, [courseId, user.email]);

  const toggleModal = () => setModal(!modal);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    anchor.click();
  };
  return (
    <>
      <style>
        {`
          .navbar-custom {
            background-color: #4A90E2;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .navbar-custom .nav-link, .navbar-custom .navbar-brand {
            color: #fff;
            font-weight: bold;
          }
          .navbar-custom .btn-danger {
            margin-left: 15px;
          }
          .card-custom {
            border-radius: 15px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
          }
          .card-custom:hover {
            transform: translateY(-10px);
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
          }
          .card-body-custom {
            padding: 25px;
          }
          .card-title-custom {
            font-size: 1.6rem;
            color: #333;
            font-weight: bold;
          }
          .card-text-custom {
            font-size: 1.1rem;
            color: #777;
          }
          .btn-custom {
            background-color: #4A90E2;
            color: #fff;
            border: none;
            transition: background-color 0.3s ease;
          }
          .btn-custom:hover {
            background-color: #357ABD;
          }
          .modal-header-custom {
            background-color: #4A90E2;
            color: #fff;
            border-bottom: none;
          }
          .modal-body-custom p {
            margin-bottom: 10px;
            color: #333;
            font-size: 1.1rem;
          }
          .modal-footer-custom {
            justify-content: flex-end;
            border-top: none;
          }
          .header-custom {
            font-size: 2.5rem;
            color: #4A90E2;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
          }
          .section-title {
            font-size: 2rem;
            color: #333;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;
          }
          .custom-divider {
            border-bottom: 2px solid #4A90E2;
            width: 60px;
            margin: 10px auto;
          }
        `}
      </style>
      <Navbar className="navbar-custom" expand="md">
        <NavbarBrand >Dashboard</NavbarBrand>
        {user && (
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#" onClick={toggleModal}>
                <i className="fas fa-user-circle fa-2x"></i>
              </NavLink>
            </NavItem>
            <NavItem>
              <Button color="danger" onClick={handleLogout}>Logout</Button>
            </NavItem>
          </Nav>
        )}
      </Navbar>

      {/* Main content */}
      <Container className="mt-5">
        {/* Your main content here */}
        {/* For example, course content */}
        <Row>
          <Col md={12}>
            <h2 className="text-center mb-4">Course Content</h2>
            {contents.map((content) => (
              <Card key={content.id} className="card-custom shadow-sm mb-4">
                <CardBody className="card-body">
                  <h4 className="card-title card-title-custom">{content.title}</h4>
                  <p className="card-text card-text-custom">{content.content}</p>
                  {content.file_path.endsWith('.pdf') && (
                    <Button color="primary" onClick={() => handleDownload(content.file_path)}>
                      Download PDF
                    </Button>
                  )}
                </CardBody>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>

      {/* Modal for user details */}
      {user && (
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>User Details</ModalHeader>
          <ModalBody>
            <p><strong>Name:</strong> {user.firstName} {user.lastName}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Mobile Number:</strong> {user.mobileNumber}</p>
            <p><strong>Address:</strong> {user.address}</p>
            <p><strong>State:</strong> {user.state}</p>
            <p><strong>City:</strong> {user.city}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
      <Container className="mt-5">
        {user && <DashboardContent user={user} courseId={courseId} />}
      </Container>

      <Container className="mt-5">
        <Row>
          <Col md={12}>
            <div className="section-title">Answer Sheets</div>
            <div className="custom-divider"></div>
            <Row>
              {answerSheets.map((sheet) => {
                const pdfDownloaded = JSON.parse(localStorage.getItem('pdfDownloaded'));
                const currentTime = new Date().getTime();
                const tenSeconds = 10 * 1000;
                const isPdfDownloaded = pdfDownloaded && (currentTime - pdfDownloaded.time) < tenSeconds;

                if (!isPdfDownloaded) {
                  return (
                    <Col md={6} lg={4} key={sheet.id}>
                      <Card className="card-custom mb-4">
                        <CardBody className="card-body-custom">
                        <CardTitle tag="h5" className="text-primary" style={{ marginBottom: "10px" }}>Please download your answer sheet</CardTitle>
                          <h4 className="card-title card-title-custom">{sheet.title}</h4>
                          <p className="card-text card-text-custom">{sheet.content}</p>
                          {sheet.file_path.endsWith('.pdf') && (
                            <Button className="btn-custom" onClick={() => handleDownload(sheet.file_path)}>
                              Download PDF
                            </Button>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;

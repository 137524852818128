import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AdminGetForm = () => {
  const [formSubmissions, setFormSubmissions] = useState([]);

  useEffect(() => {
    const fetchFormSubmissions = async () => {
      try {
        const response = await axios.get('https://thecaedge.com/find/formsubmissions');
        setFormSubmissions(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching form submissions:', error);
      }
    };

    fetchFormSubmissions();
  }, []);

  return (
    <>
      <div className="text-center my-4">
      <Link to={'/admin'} className="btn btn-outline-primary mr-2">HomePage</Link>
        
      </div>
      <h1 className='display-5 text-center my-4 fw-normal'>Schedule Downloaded</h1>
      <div style={styles.container}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Phone Number</th>
            <th style={styles.th}>Email</th>
            <th style={styles.th}>File ID</th>
            <th style={styles.th}>Submission Date</th>
          </tr>
        </thead>
        <tbody>
          {formSubmissions.map((submission) => (
            <tr key={submission.id} style={styles.tr}>
              <td style={styles.td}>{submission.name}</td>
              <td style={styles.td}>{submission.phone_number}</td>
              <td style={styles.td}>{submission.email}</td>
              <td style={styles.td}>{submission.fileId}</td>
              <td style={styles.td}>{new Date(submission.submission_date).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    color: '#444444',
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  th: {
    backgroundColor: '#f5f5f5',
    padding: '12px',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  tr: {
    '&:nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  td: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
    fontSize: '14px',
    color: '#555',
  },
};

export default AdminGetForm;

import React from 'react';
import { Container } from 'react-bootstrap';
import Header from './Header';

const Refund = () => {
  return (
    <>
    <Header/>
    <Container className="mt-5">
      <h1 className="text-center mb-4">Cancellation & Refund Policy</h1>

      <h3>1. Cancellation Policy:</h3>
      <p>
        Cancellations will be considered only if the request is made immediately after placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.
      </p>
      <p>
        THE CA EDGE does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.
      </p>

      <h3>2. Refund Policy:</h3>
      <p>
        In case of receipt of damaged or defective items, please report the same to our Customer Service team. The request will be entertained once the merchant has checked and determined the same at his own end. This should be reported within the same day of receipt of the products.
      </p>
      <p>
        If you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within the same day of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.
      </p>
      <p>
        In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.
      </p>

      <h3>3. Processing Refunds:</h3>
      <p>
        If a refund is approved by THE CA EDGE, it will take 9-15 days for the refund to be processed to the end customer.
      </p>
    </Container>
    </>
  );
};

export default Refund;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import './Features.css';

function Features() {
  return (
    <Container className="mt-5 features-container">
      <h2 className="text-center mb-5 ">Top Highlights</h2>
      <Row className="feature-row">
        <Col md={4} className="text-center feature">
          <div className="feature-box">
            <FaCheckCircle size={50} className="feature-icon" />
            <h4>Expert Faculty</h4>
            <p>Learn from industry experts with years of teaching experience.</p>
          </div>
        </Col>
        <Col md={4} className="text-center feature">
          <div className="feature-box">
            <FaCheckCircle size={50} className="feature-icon" />
            <h4>Comprehensive Material</h4>
            <p>Access extensive study materials and resources.</p>
          </div>
        </Col>
        <Col md={4} className="text-center feature">
          <div className="feature-box">
            <FaCheckCircle size={50} className="feature-icon" />
            <h4>24/7 Support</h4>
            <p>Get support anytime you need it with our dedicated team.</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Features;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const DiscountPage = () => {
  const [items, setItems] = useState([]);
  const [discounts, setDiscounts] = useState({});

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get('https://thecaedge.com/api/items');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleDiscountChange = (id, discount) => {
    setDiscounts({ ...discounts, [id]: discount });
  };

  const handleSaveDiscounts = async () => {
    try {
      const discountUpdates = Object.entries(discounts).map(async ([id, discount]) => {
        await axios.patch(`https://thecaedge.com/api/items/${id}/discount`, { discount });
      });
      await Promise.all(discountUpdates);
      fetchItems();
      alert('Discounts saved successfully!');
    } catch (error) {
      console.error('Error saving discounts:', error);
      alert('Failed to save discounts. Please try again.');
    }
  };
  return (
    <div>
    <div className="text-center my-4">
        <Link to={'/admin'} className="btn btn-outline-primary mr-2">HomePage</Link>
        
      </div>
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <h2 style={{ textAlign: 'center', color: '#333' }}>Manage Discounts</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead style={{ backgroundColor: '#f2f2f2' }}>
          <tr>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>ID</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Title</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Price</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Discount (%)</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>{item.id}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{item.title}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>&#8377;{item.amount}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                <input
                  type="number"
                  style={{ width: '50px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                  value={discounts[item.id] || item.discount}
                  onChange={(e) => handleDiscountChange(item.id, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '3px',
          cursor: 'pointer',
          display: 'block',
          margin: '0 auto'
        }}
        onClick={handleSaveDiscounts}
      >
        Save Discounts
      </button>
    </div>
    </div>
  );
};

export default DiscountPage;
